import axios from "axios";

export const sendOtp = async (mobile, login) => {
  try {
    const data = {
      mobile: `91${mobile}`,
      login: login,
      from_vendor: false,
    };
    const res = await axios.post(
      "https://webapi.theflow.ae/user/sendOtp",
      //   "http://localhost:4000/user/sendOtp",
      data
    );
    return res.data.orderId;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyOtp = async (mobile, otp, orderId, name, email_id) => {
  try {
    const data = {
      mobile: `91${mobile}`,
      otp: otp,
      orderId: orderId,
      from_vendor: false,
    };
    const res = await axios.post(
      "https://webapi.theflow.ae/user/verifyOtp",
      //   "http://localhost:4000/user/verifyOtp",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
