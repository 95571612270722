import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  data: null,
};

const successSlice = createSlice({
  name: "successModal",
  initialState,
  reducers: {
    open_success: (state, action) => {
      state.show = true;
      state.data = action.payload;
    },
    close_success: (state) => {
      state.data = null;
      state.show = false;
    },
  },
});

export const { open_success, close_success } = successSlice.actions;
export default successSlice.reducer;
