import React, { useState } from "react";
import { IoAdd, IoRemove } from "react-icons/io5";
import { ChangeCount, DeleteCart, DeleteCartItem } from "../../api/cart";
import { decrease_count, increase_count } from "../../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

function Counter({ product }) {
  const [loading, setLoading] = useState(false);
  const cart_id = useSelector((state) => state.cart?.cart_id);
  const count = useSelector((state) => state.cart?.cartObj[product?.id]?.count);
  const dispatch = useDispatch();
  const increaseQuantity = async (e) => {
    try {
      setLoading(true);
      e.stopPropagation();
      await ChangeCount(cart_id, product?.id, count + 1);
      dispatch(increase_count(product?.id));
    } catch (error) {
      toast.error("Unable to Increase");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const decreaseQuantity = async (e) => {
    try {
      setLoading(true);
      e.stopPropagation();
      dispatch(decrease_count(product?.id));
      if (count - 1 === 0) {
        await DeleteCartItem(cart_id, product?.id);
        await DeleteCart(cart_id);
        return;
      }
      await ChangeCount(cart_id, product?.id, count - 1);
    } catch (error) {
      toast.error("Unable to Decrease");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className=" flex gap-1 items rounded-md bg-gray-200 font-poppinsMedium">
      <button
        disabled={loading}
        onClick={decreaseQuantity}
        className="px-1 py-[1px] bg-green-600 text-white rounded-s-md  "
      >
        <IoRemove size={20} />
      </button>
      <div className="h-full w-[25px] py-1 text-center text-[0.825rem]">
        {count}
      </div>
      <button
        disabled={loading}
        onClick={increaseQuantity}
        className="px-1 py-[1.5px] bg-green-600 text-white rounded-e-md  "
      >
        <IoAdd size={20} />
      </button>
    </div>
  );
}

export default Counter;
