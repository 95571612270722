import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  CheckFav,
  CreateFavourite,
  DeleteFavStore,
} from "../../api/supermarkets";
import { useDispatch, useSelector } from "react-redux";
import { open_login } from "../../redux/loginModalSlice";
import { FaHeart } from "react-icons/fa";

const VendorDetails = ({ name, super_market_id }) => {
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const user_id = useSelector((state) => state.auth.user_id);
  const dispatch = useDispatch();
  const handleFavourite = async () => {
    if (loading) return;
    if (!user_id) return dispatch(open_login());
    try {
      setSaved((prev) => !prev);
      if (!saved) {
        setLoading(true);
        await CreateFavourite(super_market_id, user_id);
        toast.success("Added to Favourites.", { id: "ADDED" });
      } else {
        await DeleteFavStore(super_market_id, user_id);
        toast.success("Removed from Favourites.", { id: "REMOVED" });
      }
    } catch (error) {
      console.log(error);
      toast.error("Unable to mark.", { id: "UNABLE_TO_ADD", duration: 1000 });
      setSaved(false);
    } finally {
      setLoading(false);
    }
  };

  const check_fav = async () => {
    try {
      const isFav = await CheckFav(super_market_id, user_id);
      setSaved(isFav);
      // toast.success(isFav);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (super_market_id && user_id) {
      check_fav();
    }
  }, [super_market_id, user_id]);
  return (
    <div className="flex items-center justify-between bg-white ">
      {/* Vendor Name, Location, and Bookmark */}
      <div className="flex items-center space-x-2">
        <h3 className="font-medium font-inter text-[1.25rem] max-sm:text-[0.85rem]">
          {name}
        </h3>
        <span className="text-gray-500 text-[1.25rem] max-sm:text-[0.85rem]">
          | Business Bay
        </span>
        {/* wishlist Icon */}
        <div
          onClick={handleFavourite}
          className="cursor-pointer p-2 rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={saved ? "red" : "none"}
            viewBox="0 0 24 24"
            stroke-width={saved ? "0" : "1.5"}
            stroke="currentColor"
            class="size-7"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
            />
          </svg>
        </div>
      </div>

      {/* Ratings, Service Fee, Delivery Time, and Minimum Order */}
      <div className="flex items-center space-x-4 max-sm:hidden">
        {/* Rating */}
        <div className="flex items-center space-x-1 border-b border-b-black">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            className="w-5 h-5 text-yellow-500"
          >
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
          </svg>
          <span className="font-poppinsSemiBold text-[1rem]">4.9</span>
        </div>

        {/* Service Fee */}
        <div className="px-3 py-2 text-sm bg-gray-100 rounded-full font-poppinsMedium text-[0.8rem]">
          AED 5 Service Fee
        </div>

        {/* Delivery Time */}
        <div className="px-3 py-2 text-sm bg-gray-100 rounded-full font-poppinsMedium text-[0.8rem]">
          60 Mins
        </div>

        {/* Minimum Order */}
        <div className="px-3 py-2 text-sm bg-gray-100 rounded-full font-poppinsMedium text-[0.8rem]">
          Min. Order AED 40
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
