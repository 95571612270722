import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { IoIosArrowRoundBack } from "react-icons/io";
import ProductCard from "../../components/ProductCard/ProductCard";
import toast from "react-hot-toast";
import { CategoryProducts } from "../../api/products";
import useSupermarkets from "../../customHooks/useSupermarkets";
// import ProductCard from "./ProductCard";
// import CustomSelect from "./CustomSelect";
// import images from "../images";
// import productsData from "../data/productsData";
// import ProductCard from "./ProductCard/ProductCard";

const ProductsByCategory = () => {
  const { categoryId } = useParams();
  const supermarkets = useSupermarkets();

  const [products, setProducts] = useState([]);
  const [supermarketOptions, setSupermarketOptions] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const get_supermarket_options = () => {
    const options = supermarkets?.map((m) => {
      const label = m?.name;
      const value = m?.id;
      return { label, value };
    });
    setSupermarketOptions(options);
  };

  useEffect(() => {
    get_supermarket_options();
  }, [supermarkets]);

  const get_products = async () => {
    try {
      setLoading(true);
      const products = await CategoryProducts(categoryId);
      setProducts(products);
    } catch (error) {
      toast.error("Unable to fetch.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    get_products();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-[#F5F2F7] max-sm:h-auto max-sm:pb-[80px] px-4 max-sm:px-2 py-3 h-full">
      {/* Title and filters */}
      <div className="flex justify-between mb-4 max-sm:flex-col">
        <h2 className="font-poppinsSemiBold text-[1rem] flex items-center gap-2">
          <Link to={"/"}>
            <IoIosArrowRoundBack size={30} />
          </Link>
          <span>{products?.[0]?.category?.name}</span>
        </h2>
        <div className="flex gap-3 max-sm:mt-3 lg:w-[300px]">
          <CustomSelect
            bg={"white"}
            radius={"10px"}
            placeholder={"Vendors"}
            options={supermarketOptions}
            // onChange={(value) =>
            //   setFilters((prev) => ({ ...prev, vendor: value }))
            // }
          />
        </div>
      </div>

      {/* Content */}
      <div className="mt-5 w-full max-h-[70vh] overflow-y-auto">
        <div className="flex sm:gap-3 gap-y-3 gap-x-1 max-sm:gap-x-0 flex-wrap max-sm:justify-around max-sm:gap-1">
          {products?.length > 0 ? (
            products?.map((product) => (
              <div
                className="basis-[14rem] max-sm:basis-[11.5rem] max-[375px]:basis-[10rem] max-sm:flex-grow flex-shrink-0"
                key={product.id}
              >
                <ProductCard
                  product={product}
                  name={product?.super_market?.name}
                  vendor_id={product?.super_market.id}
                  showOffer={product.offer !== ""}
                />
              </div>
            ))
          ) : (
            <div>No products found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsByCategory;
