import { fetchMutation, fetchQuery } from "./helpers";

const supermarketQuery = `query MyQuery {
  super_market(where: {is_active: {_eq: true}}) {
    id
    name
    image_url
  }
}

`;

export const GetSupermarkets = async () => {
  try {
    const data = await fetchQuery(supermarketQuery);
    return data.super_market;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const supermarketProductsQuery = `query MyQuery($_eq: uuid = "") {
  super_market(where: {id: {_eq: $_eq}, is_active: {_eq: true}}) {
    id
    name
    image_url
    products {
      id
      image_urls
      name
      price
      product_details
      rating
      stock
      category_id
    }
  }
}
`;
const supermarketProductsQueryWithFilter = `query MyQuery($_eq: uuid = "", $_eq1: uuid = "") {
  super_market(where: {id: {_eq: $_eq}, is_active: {_eq: true}}) {
    id
    name
    image_url
    products(where: {category_id: {_eq: $_eq1}}) {
      id
      image_urls
      name
      price
      product_details
      rating
      stock
      category_id
    }
  }
}
`;
export const GetSupermarketProducts = async (
  super_market_id,
  selectedCategory = null
) => {
  try {
    let query;
    let variables = {
      _eq: super_market_id,
    };
    if (selectedCategory) {
      query = supermarketProductsQueryWithFilter;
      variables["_eq1"] = selectedCategory;
    } else {
      query = supermarketProductsQuery;
    }
    if (selectedCategory) {
    }
    const data = await fetchQuery(query, variables);
    // console.log("api data\n", data);
    return data.super_market[0];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const favouriteStoreMutation = `mutation MyMutation($super_market_id: uuid = "", $user_id: uuid = "") {
  insert_favourite_super_market_one(object: {super_market_id: $super_market_id, user_id: $user_id}) {
    id
  }
}
`;

const checkFavQuery = `query MyQuery($_eq: uuid = "", $_eq1: uuid = "") {
  favourite_super_market(where: {user_id: {_eq: $_eq}, super_market_id: {_eq: $_eq1}}) {
    id
  }
}`;

export const CheckFav = async (super_market_id, user_id) => {
  try {
    const data = await fetchQuery(checkFavQuery, {
      _eq: user_id,
      _eq1: super_market_id,
    });
    return data.favourite_super_market.length > 0;
  } catch (error) {
    throw error;
  }
};

export const CreateFavourite = async (super_market_id, user_id) => {
  try {
    const exists = await CheckFav(super_market_id, user_id);
    if (exists) return;
    const data = await fetchMutation(favouriteStoreMutation, {
      super_market_id: super_market_id,
      user_id: user_id,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const deleteFavStoreMutation = `mutation MyMutation($_eq: uuid = "", $_eq1: uuid = "") {
  delete_favourite_super_market(where: {user_id: {_eq: $_eq}, super_market_id: {_eq: $_eq1}}) {
    returning {
      id
    }
  }
}
`;

export const DeleteFavStore = async (super_market_id, user_id) => {
  try {
    const data = await fetchMutation(deleteFavStoreMutation, {
      _eq: user_id,
      _eq1: super_market_id,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const getFavouriteStoresQuery = `query MyQuery($_eq: uuid = "") {
  favourite_super_market(where: {user_id: {_eq: $_eq}}) {
    super_market {
      id
      image_url
      name
    }
  }
}`;

export const GetFavouriteStores = async (user_id) => {
  try {
    const data = await fetchQuery(getFavouriteStoresQuery, {
      _eq: user_id,
    });
    return data.favourite_super_market;
  } catch (error) {
    throw error;
  }
};
