import { createSlice } from "@reduxjs/toolkit";

const address = JSON.parse(localStorage.getItem("address")) || [];
const selected_address =
  JSON.parse(localStorage.getItem("selected_address")) || null;

const initialState = {
  address: address,
  modal: false,
  selected_address: selected_address,
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    set_address: (state, action) => {
      state.address = action.payload;
      localStorage.setItem("address", JSON.stringify(state.address));
    },
    open_delivery_address: (state) => {
      state.modal = true;
    },
    close_delivery_address: (state) => {
      state.modal = false;
    },
    set_selected_address: (state, action) => {
      state.selected_address = action.payload;
      localStorage.setItem(
        "selected_address",
        JSON.stringify(state.selected_address)
      );
    },
  },
});

export const {
  set_address,
  open_delivery_address,
  close_delivery_address,
  set_selected_address,
} = addressSlice.actions;
export default addressSlice.reducer;
