import React, { useEffect, useState } from "react";
import { GetSupermarkets } from "../api/supermarkets";

function useSupermarkets() {
  const [supermarkets, setSupermarkets] = useState([]);
  const get_supermarkets = async () => {
    try {
      const data = await GetSupermarkets();
      setSupermarkets(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    get_supermarkets();
  }, []);
  return supermarkets;
}

export default useSupermarkets;
