import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
};

const loginModalSlice = createSlice({
  name: "loginModal",
  initialState,
  reducers: {
    open_login: (state) => {
      state.show = true;
    },
    close_login: (state) => {
      state.show = false;
    },
  },
});

export const { open_login, close_login } = loginModalSlice.actions;
export default loginModalSlice.reducer;
