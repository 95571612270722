import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
};

const logoutModalSlice = createSlice({
  name: "logoutModal",
  initialState,
  reducers: {
    open_logout: (state) => {
      state.show = true;
    },
    close_logout: (state) => {
      state.show = false;
    },
  },
});

export const { open_logout, close_logout } = logoutModalSlice.actions;
export default logoutModalSlice.reducer;
