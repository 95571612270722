// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import categoriesReducer from "../features/categories/categoriesSlice";

import authReducer from "./authSlice";
import productDescriptionReducer from "./productDescriptionSlice";

import notificationsReducer from "./notificationSlice";
import dispalyCartReducer from "./cartDisplaySlice";
import dispalyCheckoutReducer from "./checkoutDisplaySlice";
import activeIconMobileReducer from "./activeIconMobile";
import loginModalReducer from "./loginModalSlice";
import cartReducer from "./cartSlice";
import logoutModalReducer from "./logoutModalSlice";
import successReducer from "./successSlice";
import profileReducer from "./profileSlice";
import addressReducer from "./addressSlice";
import searchReducer from "./searchSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    loginModal: loginModalReducer,
    categories: categoriesReducer,
    cart: cartReducer,
    notifications: notificationsReducer,
    product_description: productDescriptionReducer,
    showCart: dispalyCartReducer,
    showCheckout: dispalyCheckoutReducer,
    activeIcon: activeIconMobileReducer,
    logoutModal: logoutModalReducer,
    successModal: successReducer,
    profile: profileReducer,
    address: addressReducer,
    search: searchReducer,
  },
});

export default store;
