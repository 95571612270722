import { fetchMutation, fetchQuery } from "./helpers";

const createCartMutation = `mutation MyMutation($vendor_id: uuid = "", $user_id: uuid = "") {
  insert_cart_one(object: {vendor_id: $vendor_id, user_id: $user_id}) {
    id
  }
}`;

export const CreateCart = async (vendor_id, user_id) => {
  try {
    const data = await fetchMutation(createCartMutation, {
      vendor_id: vendor_id,
      user_id: user_id,
    });
    return data.insert_cart_one.id;
  } catch (error) {
    throw error;
  }
};

const addCartItemMutation = `mutation MyMutation($super_market_id: uuid = "", $cart_id: uuid = "", $count: Int = 10, $json_details: jsonb = "", $product_id: uuid = "") {
  insert_cart_items_one(object: {super_market_id: $super_market_id, cart_id: $cart_id, count: $count, product_id: $product_id, json_details: $json_details}) {
    id
  }
}`;

export const AddCartItem = async (vendor_id, cart_id, product) => {
  try {
    const data = await fetchMutation(addCartItemMutation, {
      super_market_id: vendor_id,
      cart_id: cart_id,
      product_id: product.id,
      count: product.count,
      json_details: product.product_details,
    });
  } catch (error) {
    throw error;
  }
};

const chageCount = `mutation MyMutation($_eq: uuid = "", $_eq1: uuid = "", $count: Int = 10) {
  update_cart_items(where: {cart_id: {_eq: $_eq}, product_id: {_eq: $_eq1}}, _set: {count: $count}) {
    returning {
      id
    }
  }
}`;

export const ChangeCount = async (cart_id, product_id, count) => {
  console.log(cart_id, product_id, count);
  console.log("ids abve");
  try {
    const data = await fetchMutation(chageCount, {
      _eq: cart_id,
      _eq1: product_id,
      count: count,
    });
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

const deleteCartItem = `mutation MyMutation($_eq: uuid = "", $_eq1: uuid = "") {
  delete_cart_items(where: {cart_id: {_eq: $_eq}, product_id: {_eq: $_eq1}}) {
    returning {
      id
    }
  }
}`;

export const DeleteCartItem = async (cart_id, product_id) => {
  try {
    const data = await fetchMutation(deleteCartItem, {
      _eq: cart_id,
      _eq1: product_id,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const getCart = `query MyQuery($_eq: uuid = "", $_eq1: uuid = "") {
  cart(where: {user_id: {_eq: $_eq1}, is_order_placed: {_eq: false}}) {
    cart_items {
      product {
        id
        name
        price
        image_urls
        product_details
        stock
        rating
        super_market {
          name
        }
      }
      count
    }
    id
    vendor_id
  }
}
`;

export const GetCart = async (user_id) => {
  try {
    const data = await fetchQuery(getCart, {
      _eq1: user_id,
    });
    // console.log("cart");
    // console.log(data.cart[0]);
    return data.cart[0];
  } catch (error) {
    throw error;
  }
};

const deleteCartMutation = `mutation MyMutation($id: uuid = "") {
  delete_cart_by_pk(id: $id) {
    id
  }
}`;
export const DeleteCart = async (cart_id) => {
  try {
    const data = await fetchMutation(deleteCartMutation, {
      id: cart_id,
    });
    return;
  } catch (error) {
    throw error;
  }
};
