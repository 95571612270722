import React from "react";
import images from "../../images";
import { useSelector } from "react-redux";
import Counter from "../Counter/Counter";

function CartItemStrip({ product_id }) {
  const item = useSelector((state) => state.cart.cartObj[product_id]);
  return (
    <div className="flex items-center gap-3">
      {/* Image */}
      <div className="bg-white flex items-center justify-center p-3 rounded-lg overflow-hidden">
        <img
          src={item?.image_urls[0]}
          alt={item?.name}
          className="w-[40px] scale-105"
        />
      </div>
      {/* Name and qunat */}
      <div className="self-start font-inter font-medium text-[0.875rem]">
        <div>{item?.name}</div>
        <div className="text-[0.75rem]">AED {item?.price}</div>
        <div>
          {item?.product_details?.weight} x {item?.count}
        </div>
      </div>
      {/* buttons */}
      <div className="ms-auto flex items-center gap-2 font-poppinsMedium px-2 py-1 rounded-lg text-[0.85rem]">
        <Counter product={item} />
      </div>
    </div>
  );
}

export default CartItemStrip;
