import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyOtp } from "../../api/auth";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { initiate_user_data } from "../../redux/authSlice";
import { close_login } from "../../redux/loginModalSlice";

function OtpModal({ setShowOtpModal, to, otpData }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const otpInput = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [focusedEl, setFocusedEl] = useState(null);
  const navigate = useNavigate();
  const handleChange = (e, idx) => {
    const input = e.target;
    const val = input.value;
    otpInput[focusedEl].current.value = val;
    // Moving to next feild
    if (val.length === 1 && idx < otpInput.length - 1) {
      otpInput[idx + 1].current.focus();
    }
  };
  const handleKeyDown = (e, idx) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      if (idx >= 0) {
        otpInput[focusedEl].current.value = "";
        otpInput[idx > 0 ? idx - 1 : 0].current.focus();
      }
    }
  };
  const closeOtpModal = () => {
    setShowOtpModal(false);
  };
  const submitOtp = async () => {
    try {
      setLoading(true);
      let otp = "";
      for (let i = 0; i < otpInput.length; i++) {
        otp = otp + otpInput[i].current.value;
      }
      if (otp.length < 6) return toast.error("Invalid OTP!");
      const { mobileNumber, orderId } = otpData;
      const res = await verifyOtp(mobileNumber, otp, orderId);
      toast.success("Verified");
      // console.log(res.data.token, res.data.user_id);
      const payload = {
        token: res.data.token,
        user_id: res.data.user_id,
      };
      dispatch(initiate_user_data(payload));
      navigate(`${to}`);
      setShowOtpModal(false);
      return dispatch(close_login());
    } catch (error) {
      toast.error("Something went Wrong!");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="modal z-50 flex items-center justify-center ">
      <div className=" bg-white p-0 w-[30%] rounded-xl max-sm:w-[90%]">
        <div className="flex justify-between px-5 py-3 bg-black text-white font-inter font-medium tracking-wide rounded-t-xl">
          <div className="">Verify Phone</div>
          <button onClick={closeOtpModal} className="">
            {" "}
            X{" "}
          </button>
        </div>
        <div className="bg-white py-3 px-5 mt-3">
          <div className="font-poppinsLight">
            Code sent to{" "}
            <span className="font-poppinsMedium">
              +91 {otpData.mobileNumber}
            </span>{" "}
          </div>
        </div>
        <div className="flex justify-evenly mt-4 mb-3 w-[90%] mx-auto">
          {otpInput.map((inputref, idx) => {
            return (
              <input
                className="border bg-white w-[10%] max-sm:w-[15%] h-[50px] text-center text-[1rem]"
                key={idx}
                maxLength={1}
                minLength={1}
                ref={inputref}
                type="text"
                onChange={(e) => handleChange(e, idx)}
                onKeyDown={(e) => handleKeyDown(e, idx)}
                onFocus={(e) => setFocusedEl(idx)}
              />
            );
          })}
        </div>
        <div className="mt-6 px-5 py-3 flex justify-between items-center text-[1rem] mb-6 max-md:text-[0.8rem]">
          <div className="font-poppinsLight">
            Didn't receive OTP?{" "}
            <span className="font-poppinsMedium cursor-pointer">
              Send Again
            </span>
          </div>
          <div>
            <button
              disabled={loading}
              onClick={submitOtp}
              className={`bg-[#217229] ${
                loading && "bg-gray-300"
              } py-3 px-5 rounded-3xl text-white font-poppinsMedium tracking-wide`}
            >
              {loading ? "Loading..." : "Verify"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpModal;
