import React, { useState } from "react";
import images from "../../images";
import { useNavigate } from "react-router-dom";
import OtpModal from "../../components/OtpModal/OtpModal";
import { sendOtp } from "../../api/auth";
import toast from "react-hot-toast";

function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otpData, setOtpData] = useState(null);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const mobileRegex = /^\d{10}$/; // Regex to validate 10-digit phone number
      if (!mobileRegex.test(mobileNumber)) {
        return toast.error("Invalid Mobile!");
      }
      if (!isChecked) {
        return toast.error("Accepte TnC");
      }
      const orderId = await sendOtp(mobileNumber, false);
      setOtpData({ mobileNumber, orderId });
      toast.success("OTP Sent!");
      setShowOtpModal(true);
    } catch (error) {
      if (error && error.response) {
        return toast.error(error.response.data.error);
      }
      toast.error("Something went Wrong!");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="text-center tracking-wide">
        <div className="text-[2rem] text-black">Create Account</div>
        <div className="text-black opacity-[56%] font-poppins-500 pb-5">
          Please Enter Your Details
        </div>
        <div className="w-full diminishing-line"></div>
        <div className="mt-10">
          <form>
            <div className="input-container mt-4">
              <div className="p-3 bg-[#B7BBC11F] bg-opacity-[12%]">
                <img className="w-[20px]" src={images.phone} alt="name" />
              </div>
              <div className="flex-1 ">
                <input
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  className="input-text"
                  type="input"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div className="text-start mt-5 tracking-wide flex items-center">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                className="scale-150 mr-2"
              />
              <div className="font-poppinsMedium text-[0.8rem] text-[#676B89]">
                By continuing, you accept our{" "}
                <span className="text-black">Terms and Conditions</span> and{" "}
                <span className="text-black">Privacy Policy</span>
              </div>
            </div>
            <button
              disabled={loading}
              onClick={handleSubmit}
              className={`continue mt-5 bg-[#217229] ${
                loading && "bg-gray-300"
              } text-white w-full p-4 rounded-xl font-inter font-semibold text-[1.15rem]`}
            >
              {loading ? "Loading" : "Continue"}
            </button>
          </form>
          <div className="mt-10 text-[#293286] font-inter font-semibold text-[1rem]">
            Already have an Account?{" "}
            <span
              onClick={() => navigate("/")}
              className="cursor-pointer underline"
            >
              Login
            </span>{" "}
          </div>
        </div>
      </div>
      {showOtpModal && (
        <OtpModal
          otpData={otpData}
          to={"/"}
          setShowOtpModal={setShowOtpModal}
        />
      )}
    </>
  );
}

export default SignUp;
