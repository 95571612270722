import { fetchQuery } from "./helpers";

const categoriesQuery = `query MyQuery {
  category {
    id
    name
    image_url
  }
}
`;

export const GetCategories = async () => {
  try {
    const data = await fetchQuery(categoriesQuery);
    // console.log("categories", data.category);
    return data.category;
  } catch (error) {
    throw error;
  }
};
