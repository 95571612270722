import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  text: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    set_products: (state, action) => {
      state.value = action.payload;
    },
    set_text: (state, action) => {
      state.text = action.payload;
    },
  },
});

export const { set_products, set_text } = searchSlice.actions;
export default searchSlice.reducer;
