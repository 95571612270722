import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { GetCategories } from "../api/categories";

function useCategories() {
  const [categories, setCategories] = useState([]);
  const get_categories = async () => {
    try {
      const categories = await GetCategories();
      setCategories(categories);
    } catch (error) {
      console.log(error);
      toast.error("Unable to get Categories");
    }
  };
  useEffect(() => {
    get_categories();
  }, []);
  return categories;
}

export default useCategories;
