import React, { useState } from "react";
import images from "../../images";
import { useDispatch, useSelector } from "react-redux";
import { open_product_description } from "../../redux/productDescriptionSlice";
import { IoAdd } from "react-icons/io5";
import { IoRemove } from "react-icons/io5";
import toast from "react-hot-toast";
import {
  AddCartItem,
  ChangeCount,
  CreateCart,
  DeleteCartItem,
} from "../../api/cart";
import {
  add_item,
  decrease_count,
  increase_count,
  set_cart_id,
  set_name,
  set_vendor_id,
} from "../../redux/cartSlice";
import { open_login } from "../../redux/loginModalSlice";
import Counter from "../Counter/Counter";

function ProductCard({ showOffer = false, product, name, vendor_id }) {
  const cart_id = useSelector((state) => state.cart?.cart_id);
  const shop_id = useSelector((state) => state.cart?.vendor_id);
  const user_id = useSelector((state) => state.auth.user_id);
  const [loading, setLoading] = useState(false);
  const count = useSelector(
    (state) => state.cart?.cartObj[product.id]?.count || 0
  );
  const dispatch = useDispatch();
  const openDescription = () => {
    dispatch(
      open_product_description({
        ...product,
        super_market: name,
        vendor_id: vendor_id,
      })
    );
  };
  // Adding product to cart
  const handleAdd = async (e) => {
    e.stopPropagation();
    if (!user_id) {
      return dispatch(open_login());
    }
    try {
      setLoading(true);
      let newCartId = null;
      if (!cart_id) {
        // Crate a cart and store this id
        newCartId = await CreateCart(vendor_id, user_id);
        dispatch(set_cart_id(newCartId));
      }
      if (!shop_id) {
        // set vendor id
        dispatch(set_vendor_id(vendor_id));
      } else if (vendor_id !== shop_id) {
        return toast.error("Only one Shop is Allowed.");
      }
      // Here add item to cart items
      await AddCartItem(vendor_id, cart_id || newCartId, {
        ...product,
        count: 1,
      });
      dispatch(add_item({ ...product, count: 1 }));
      dispatch(set_name(name));
      toast.success("Item added");
    } catch (error) {
      toast.error("Unable to Add.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      onClick={openDescription}
      className="bg-white rounded-xl pt-1 cursor-pointer h-full"
    >
      <div className="px-1">
        <div className="flex h-[130px]   items-center justify-center rounded-xl bg-[#EBEEF2] p-2 border relative">
          <img
            loading="lazy"
            src={product?.image_urls?.[0] || images.sampleLays}
            className="object-contain w-[60%] h-[100px] mix-blend-multiply"
          />
          {/* offer sticker */}
          {showOffer && (
            <div className="font-poppinsLight text-[0.88rem] absolute bottom-1 right-1 bg-white px-3 py-1 rounded-3xl">
              12% OFF
            </div>
          )}
        </div>
        <div
          title={product?.name}
          className="font-poppinsMedium text-[0.825rem] line-clamp-1 mx-3 mt-2"
        >
          {product?.name}
        </div>
        <div className=" font-poppinsMedium text-[0.825rem] underline underline-[#217229] underline-offset-4 text-[#217229] mx-3 mt-2 cutoff-text-one">
          {name || "Shop"}
        </div>
        <div className="flex items-center gap-5 mx-3 mt-2">
          {product?.product_details?.weight && (
            <div className="font-poppinsMedium text-[#00000094] text-[0.825rem]">
              {product?.product_details?.weight}
            </div>
          )}
          <div className="flex items-center gap-1">
            <div>
              <img src={images.star} className="w-[13px]" />
            </div>
            <div className="font-poppinsMedium text-[0.825rem]">
              {product?.rating || "Not Available"}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between p-3 px-3 rounded-b-xl text-black">
        <div className="font-poppinsSemiBold text-[0.825rem] line-clamp-1 ">
          {product?.price} AED
        </div>
        {count === 0 && (
          <button
            disabled={loading}
            onClick={handleAdd}
            className={`text-[#217229] ${
              loading && "text-gray-300 border-gray-300"
            } border border-[#217229] px-3 py-1 rounded-lg text-[0.85rem] font-poppinsMedium`}
          >
            {loading ? "Loading..." : "ADD"}
          </button>
        )}
        {count > 0 && (
          // <div className=" flex gap-1 items rounded-md bg-gray-200 font-poppinsMedium">
          //   <button
          //     disabled={loading}
          //     onClick={increaseQuantity}
          //     className="px-1 py-[1px] bg-green-600 text-white rounded-s-md  "
          //   >
          //     <IoAdd size={20} />
          //   </button>
          //   <div className="h-full w-[25px] py-1 text-center text-[0.825rem]">
          //     {count}
          //   </div>
          //   <button
          //     disabled={loading}
          //     onClick={decreaseQuantity}
          //     className="px-1 py-[1.5px] bg-green-600 text-white rounded-e-md  "
          //   >
          //     <IoRemove size={20} />
          //   </button>
          // </div>
          <div className="max-sm:scale-75">
            <Counter count={count} loading={loading} product={product} />
          </div>
        )}
        {/* <div className="flex items-center gap-3">
          <button className="text-[1rem]"> - </button>
          <div> 0 </div>
          <button className="text-[1rem]"> + </button>
        </div> */}
      </div>
    </div>
  );
}

export default ProductCard;
