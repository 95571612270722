import React, { useState } from "react";
import images from "../../images";
import CartItemStrip from "../CartItemStrip/CartItemStrip";

import { useDispatch, useSelector } from "react-redux";
import { close_checkout } from "../../redux/checkoutDisplaySlice";
import { open_cart } from "../../redux/cartDisplaySlice";
import toast from "react-hot-toast";
import { CreateOrder } from "../../api/order";
import { remove_cart } from "../../redux/cartSlice";
import { set_selected_address } from "../../redux/addressSlice";

function CheckoutModal({}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const cart = useSelector((state) => state.cart.cartArr);
  const cart_id = useSelector((state) => state.cart.cart_id);
  const super_market_id = useSelector((state) => state.cart.vendor_id);
  const user_id = useSelector((state) => state.auth.user_id);
  const vendor_name = useSelector((state) => state.cart.vendor_name);
  const addresses = useSelector((state) => state.address.address);
  const [allLocations, setAllLocations] = useState(false);
  const selectedAddress = useSelector(
    (state) => state.address.selected_address || state.address.address[0]
  );
  const handlePay = async () => {
    try {
      setLoading(true);
      await CreateOrder(user_id, cart_id, super_market_id);
      dispatch(close_checkout());
      dispatch(remove_cart());
      toast.success("Order Succesfull!");
    } catch (error) {
      toast.error("Unable to Order!");
    } finally {
      setLoading(false);
    }
  };
  const handleSelectAddress = (address) => {
    dispatch(set_selected_address(address));
  };

  return (
    <div className="modal flex justify-center items-center ">
      <div className=" w-[600px] rounded-xl overflow-hidden">
        {/* Cart Window */}
        <div
          className="bg-gray-100 h-full shadow-lg p-6 relative transform transition-transform duration-300" // Initially off-screen
        >
          {/* Close Button */}
          <button
            onClick={() => {
              dispatch(close_checkout());
              dispatch(open_cart());
            }}
            className="absolute top-4 right-4 text-gray-600 text-2xl font-bold"
          >
            &times;
          </button>

          {/* Delivery and Timer */}
          {/* <div className="flex items-center mb-2"> */}
          {/* Timer Icon */}
          {/* <span className="text-xl text-gray-600 mr-2">&#9202;</span>
          </div> */}

          {/* Delivery Estimate */}
          {/* <div className="flex items-center justify-center mb-7">
            <div className="text-center border rounded-full shadow-lg h-[80px] w-[80px] flex items-center justify-center p-1">
              <p className="text-[0.690rem]">
                <span className="font-bold text-gray-900">30 Min</span>{" "}
                <span className="text-gray-500">Delivery</span>
              </p>
            </div>
          </div> */}

          {/* Scrollable Cart Items */}
          <h3 className="text-[0.755rem]  ml-2 font-bold text-gray-800 mb-2">
            {vendor_name}
          </h3>
          <div
            className="flex-grow overflow-y-scroll pl-1 h-[60vh] "
            // onWheel={handleWheelScrollCart} // Handle mouse wheel scroll for cart items
          >
            <div className=" pb-64">
              {cart.map((product_id) => (
                <div className="mt-4">
                  <CartItemStrip product_id={product_id} />
                </div>
              ))}
            </div>
          </div>

          {/* Fixed Checkout Button */}
          <div className="absolute bottom-0 left-0 right-0 p- bg-gray-100">
            {/* card  */}
            <div
              style={{
                boxShadow: "0px -5px 24.8px 0px #0000001C",
              }}
              className="p-4 bg-white rounded-xl"
            >
              {/* Address Section */}
              <div className="border-b pb-6">
                <div className="flex items-start justify-between ">
                  <div className="flex gap-4">
                    <div className="bg-gray-100 w-[50px] h-[50px] flex justify-center items-center rounded-xl">
                      <img src={images.location_home} className="w-[20px]" />
                    </div>
                    <div>
                      <div className="flex items-center gap-2">
                        <h2 className="text-[1.125rem] font-poppinsMedium capitalize">
                          Delivering to{" "}
                          {selectedAddress?.address?.type ||
                            addresses?.[0]?.address?.type}
                        </h2>
                      </div>
                      <p className="text-gray-600 mt-1 text-[1rem] font-poppinsLight line-clamp-1">
                        {selectedAddress?.address?.address ||
                          addresses?.[0]?.address?.address}
                        ,{" "}
                        {selectedAddress?.address?.area ||
                          addresses?.[0]?.address?.area}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => setAllLocations(!allLocations)}
                    className="text-green-700 font-medium hover:text-green-800"
                  >
                    {allLocations ? "Close" : "Change"}
                  </button>
                </div>
                {/* all locations */}
                {allLocations && (
                  <div
                    className={
                      "text-white max-h-[200px] overflow-auto flex gap-2 flex-wrap items-start p-2"
                    }
                  >
                    {addresses?.map((address) => (
                      <button
                        onClick={() => handleSelectAddress(address)}
                        className={`${
                          selectedAddress?.id === address?.id
                            ? "bg-green-600"
                            : "bg-white text-black"
                        } border  rounded-lg w-[45%] flex-grow font-poppinsMedium flex flex-col p-2`}
                      >
                        <div className="w-full capitalize text-center">
                          {address?.address.type}
                        </div>
                        <span>{address?.address.name}</span>
                        <span>{address?.address.phone}</span>
                        <span>
                          {address?.address.address},{address?.address.area}
                        </span>
                        <span>{address?.address.pincode}</span>
                      </button>
                    ))}
                  </div>
                )}
              </div>

              {/* Payment Section */}
              <div className="flex items-center justify-between mt-3">
                <div>
                  {/* <div className="text-gray-700 font-medium mb-1">
                    PAY USING CREDIT CARD ▼
                  </div> */}
                  <div className="font-poppinsMedium">Cash on Delivery</div>
                </div>
                {loading ? (
                  <div className="border border-slate-300 px-3 py-1 rounded-md font-poppinsMedium text-slate-300">
                    Loading...
                  </div>
                ) : (
                  <button
                    onClick={handlePay}
                    className="justify-center bg-green-700 text-white px-4 py-2 rounded-full flex items-center gap-2 hover:bg-green-800 transition-colors"
                  >
                    <span>₹ 200</span>
                    <span className="text-xl">→</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutModal;
