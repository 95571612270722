import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  user: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    set_orders: (state, action) => {
      state.orders = action.payload;
    },
    set_user: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { set_orders, set_user } = profileSlice.actions;
export default profileSlice.reducer;
