import { createSlice } from "@reduxjs/toolkit";

let activeIcon = localStorage.getItem("active_icon") || "home";

const locArr = window.location.pathname.split("/");
if (locArr.includes("order-history")) {
  activeIcon = "orders";
} else if (locArr.includes("favourite-stores")) {
  activeIcon = "fav_vendors";
} else if (locArr.includes("profile")) {
  activeIcon = "profile";
} else {
  activeIcon = "home";
}

const initialState = {
  activeIcon: activeIcon,
};

const activeIconMobile = createSlice({
  name: "activeIcon",
  initialState,
  reducers: {
    set_icon: (state, action) => {
      state.activeIcon = action.payload;
      localStorage.setItem("active_icon", action.payload);
    },
  },
});

export const { set_icon } = activeIconMobile.actions;
export default activeIconMobile.reducer;
