import { createSlice } from "@reduxjs/toolkit";

// Getting token, user_id from local storage
const user_data =
  JSON.parse(localStorage.getItem("flow_customer_data")) || null;

const initialState = {
  token: user_data?.token || null,
  user_id: user_data?.user_id || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // function to set token, user_id in redux.
    initiate_user_data: (state, action) => {
      const { token, user_id } = action.payload;
      state.token = token;
      state.user_id = user_id;
      // Persisting token, user_id in localStorage.
      localStorage.setItem(
        "flow_customer_data",
        JSON.stringify({ token, user_id })
      );
    },
  },
});

export const { initiate_user_data } = authSlice.actions;
export default authSlice.reducer;
