import { fetchQuery } from "./helpers";

const categoryFilterQuery = `query MyQuery($_eq: uuid = "") {
  product(where: {category_id: {_eq: $_eq}}) {
    id
    name
    price
    image_urls
    product_details
    rating
    stock
    category {
      name
    }
    super_market {
      id
      name
    }
  }
}

`;

export const CategoryProducts = async (category_id) => {
  try {
    const data = await fetchQuery(categoryFilterQuery, {
      _eq: category_id,
    });
    return data.product;
  } catch (error) {
    throw error;
  }
};
